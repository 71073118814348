
import { mapState } from 'vuex'
import UIHeader from '@/layouts/components/UIHeader/UIHeader.vue'
import Container from '@/components/identity/Layout/Container.vue'
import Footer from '@/components/identity/Layout/Footer.vue'
import Link from '@/components/ui/Link/Link.vue'
import PDEBanner from '@/components/pde/PDEBanner.vue'

export default {
  name: 'Identity',
  components: {
    Container,
    layoutFooter: Footer,
    PDEBanner,
    UIHeader,
    'app-link': Link,
  },

  // Pages that use this layout should only be accessed while unauthenticated
  middleware: ['redirects', 'unAuthMiddleware'],

  computed: {
    ...mapState('navigation', ['registrationFooterVisible']),
    privacyPolicy() {
      return {
        copy: this.$t('account.footer.privacyPolicy.copy'),
        link: this.$t('account.footer.privacyPolicy.link'),
        target: this.$t('account.footer.privacyPolicy.target'),
        color: 'text-pureblack-20 hover:text-pureblack-20',
      }
    },
  },
}
