import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center bg-pureblack-5 px-24 py-32 border-t-1 border-solid border-pureblack-20" }
const _hoisted_2 = { class: "leading-7 m-0 mb-12" }
const _hoisted_3 = { class: "m-0" }
const _hoisted_4 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString($options.getTout), 1),
    _createElementVNode("h2", _hoisted_3, [
      _createElementVNode("a", {
        href: $options.getLink,
        class: "text-primary-500 no-underline leading-10 m-0"
      }, _toDisplayString($options.getLinkText), 9, _hoisted_4)
    ])
  ]))
}